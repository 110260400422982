/* eslint-disable no-undef */
import axios from "axios"
import { API_URL, EMBODIMENT_PORT } from "../constants.js"

export const getPluginManifest = async (domain, headers) => {
  // remove forward slash from the end of the domain
  // domain = domain ? domain.replace(/\/$/, "") : null;

  // Normalize the domain to always include https protocol if not present
  const normalizedDomain =
    domain && !domain.startsWith("http://") && !domain.startsWith("https://")
      ? `https://${domain}`
      : domain
  try {
    const { data } = await axios.get(
      `${normalizedDomain || API_URL}/chatbot/chat-configs`,
      {
        headers,
      }
    )
    return data
  } catch {
    return domain
  }
}

const getPort = (port) => {
  const host = window?.location?.host
  const defaultPort = EMBODIMENT_PORT
  if (host.includes("localhost")) {
    // when running this app directly for development
    return port || defaultPort
  }

  return port || window?.location?.port || defaultPort
}

export const getNormalizedDomain = (domain, port) => {
  return domain &&
    !domain.startsWith("http://") &&
    !domain.startsWith("https://")
    ? domain.includes("localhost") || domain.includes("localagent")
      ? `http://${domain}:${getPort(port)}`
      : `https://${domain}`
    : domain
}

export const uploadFiles = async ({ port, files, domain, headers }) => {
  try {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append("files", file)
    })

    const authToken = localStorage.getItem("authToken")

    const requestHeaders = {
      ...(window._smyth_req_headers || {}),
      ...headers,
    }

    if (authToken) {
      requestHeaders["X-Auth-Token"] = authToken
    }

    const response = await fetch(
      `${getNormalizedDomain(domain, port) || API_URL}/chatbot/upload`,
      {
        method: "POST",
        body: formData,
        credentials: "include",
        headers: requestHeaders,
      }
    )

    const newToken = response.headers.get("x-auth-token")
    if (newToken) {
      localStorage.setItem("authToken", newToken)
    }

    if (!response.ok) {
      throw new Error(`Upload failed: ${response.statusText}`)
    }

    return await response.json()
  } catch (error) {
    console.error("Error uploading files:", error)
    throw error
  }
}
