import ReactDOM from "react-dom/client"
import "./index.css"
import { ChatBox } from "./components/chatbox/index.jsx"
import { DEFAULT_CHATBOT_CONTAINER_ID } from "./constants"

function renderChatBot(props) {
  const chatbotId = props.containerId || DEFAULT_CHATBOT_CONTAINER_ID
  ReactDOM.createRoot(document.getElementById(chatbotId)).render(
    <ChatBox {...props} />
  )
}

window.ChatBot = {
  init: (props) => {
    const chatbotId = props.containerId || DEFAULT_CHATBOT_CONTAINER_ID
    if (!document.getElementById(chatbotId)) { // if the chatbot container is not found, create it
      const div = document.createElement("div")
      div.id = chatbotId
      document.body.appendChild(div)
    }
    renderChatBot(props)
  },
}

if (import.meta.env?.VITE_APP_ENV === "development") {
  window.ChatBot.init({
    isChatOnly: true,
    allowAttachments: true,
    icon: "./ink-logo.png",
    containerId: "smyth-chatbot-v2",
    // domain: import.meta.env.VITE_API_URL,
    // domain: "http://localhost",
    domain: "cm6jskqf2021d2g3i68tfp3ev.localagent.stage.smyth.ai"
  })
}
