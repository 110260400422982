/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import "./../chatbox/styles.scss";

export const UserMessage = (props) => {
  const { id, content, attachments } = props.message;
  const { humanBubbleClass } = props;

  const renderContent = (_content) => {
    // split the content by new line
    const contentArray = _content.split("\n");
    // render the content with <br /> tag
    return contentArray.map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));
  }

  const renderAttachments = () => {
    if (!attachments || attachments.length === 0) return null;

    return (
      <div className="attachments-container">
        {attachments.map((attachment, index) => (
          <div key={index} className="attachment-item">
            {attachment.type.startsWith('image/') ? (
              <div className="image-preview">
                <img
                  src={attachment.blobUrl || attachment.url}
                  alt={attachment.name}
                  onError={(e) => {
                    if (attachment.url) {
                      e.target.src = attachment.url;
                    }
                  }}
                />
              </div>
            ) : (
              <div className="file-preview">
                <div className="file-icon">
                  <span className="file-extension">
                    {attachment.name.split('.').pop().toUpperCase()}
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="chat-message user" key={id}>
      <div
        className={`${"chat-content"} ${humanBubbleClass}`}
        style={{
          color: props.colors?.humanBubbleColors?.textColor,
          background: `linear-gradient(to right, ${props.colors?.humanBubbleColors?.backgroundColorStart}, ${props.colors?.humanBubbleColors?.backgroundColorEnd})`,
        }}
      >
        <div className="chat-text">{renderContent(content)}</div>
      </div>
      {renderAttachments()}
    </div>
  );
};

UserMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
      blobUrl: PropTypes.string
    }))
  }).isRequired,
  humanBubbleClass: PropTypes.string,
};
